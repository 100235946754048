import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Image from 'common/Image';

import './Checklist.scss';

const Checklist = () => {
  const {
    allChecklist: { nodes },
  } = useStaticQuery(graphql`
    {
      allChecklist {
        nodes {
          checklistLogo {
            ...UmbracoImage
          }
          checklistText
          checklist {
            value
          }
        }
      }
    }
  `);

  const { checklistText, checklist, checklistLogo } = nodes[0];

  return (
    <div className="checklist" data-testid="checklist">
      <div className="checklist__description">
        <div className="checklist__text">{checklistText}</div>
        <Image imageData={checklistLogo} alt={checklistLogo.altText} className="checklist__img" />
      </div>
      <div className="checklist__solutions">
        {checklist.map((solution) => (
          <div className="checklist__solution" key={solution?.value}>
            {solution?.value}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Checklist;
