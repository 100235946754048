import React, { FC } from 'react';
import { graphql } from 'gatsby';

import Layout from 'layout/Layout';
import Checklist from 'common/Checklist';
import PageSchema from 'common/PageSchema';
import Seo from 'common/Seo';
import SignupBanner from 'common/SignupBanner';
import InfoBanner from 'components/InfoBanner';
import SignUpCards from 'components/SignUpCards';

import Carousel from '../../common/Carousel';

const HomePage: FC<HomepageTypes.HomepageProps> = ({
  data: {
    homepage: { urls, seo },
    homepageBlocks,
  },
}) => {
  const { title, keywords, description } = seo;
  const { mainCarousel, infoBanner, signUpCards } = homepageBlocks;

  return (
    <Layout headerTransparent>
      <Seo {...{ urls, seo }} />
      <PageSchema
        type="WebPage"
        name={title}
        data={{
          metaTitle: title,
          metaDescription: description,
          metaKeywords: keywords,
        }}
      />
      {mainCarousel ? (
        <Carousel
          {...{
            carouselImages: mainCarousel,
            className: 'main-carousel',
          }}
        />
      ) : null}
      <SignupBanner />
      <SignUpCards {...{ cards: signUpCards }} />
      <Checklist />
      <InfoBanner {...{ infoBanner }} />
    </Layout>
  );
};

export const query = graphql`
  query ($lang: String) {
    homepage(lang: { eq: $lang }) {
      urls {
        lang
        href
      }
      seo {
        ...SEOStructureFragment
      }
    }
    homepageBlocks(lang: { eq: $lang }) {
      mainCarousel {
        carouselItems {
          ...CarouselItemsType
        }
      }
      infoBanner {
        ...InfoBannerTypeFragment
      }
      signUpCards {
        sectionTitle
        cardsList {
          ...SignUpCardsItemType
        }
      }
    }
  }
`;

export default HomePage;
