import React from 'react';
import { graphql, navigate, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';

import DangerouslySetInnerHtml from 'layout/DangerouslySetInnerHtml';
import Button from 'common/Button';
import { HOMEPAGE_PATH } from 'utils/constants';

import './SignupBanner.scss';

const SignupBanner = () => {
  const {
    allSignupBanner: { nodes },
  } = useStaticQuery(graphql`
    {
      allSignupBanner {
        nodes {
          signupButton {
            ariaLabel
            backgroundColor
            label
            link {
              url
            }
            size
          }
          signupText
          signupTitle
        }
      }
    }
  `);

  const { pathname } = useLocation();
  const { signupTitle, signupText, signupButton } = nodes[0];
  const { ariaLabel, backgroundColor, size, label, link } = signupButton || {};
  const { url } = link || {};

  return (
    <div className="signup-banner" data-testid="signup-banner">
      <div className="signup-banner__description">
        {pathname === HOMEPAGE_PATH ? (
          <h1 className="signup-banner__title">{signupTitle}</h1>
        ) : (
          <p className="signup-banner__title">{signupTitle}</p>
        )}
        <DangerouslySetInnerHtml html={signupText} />
      </div>
      <div className="signup-banner__sign-up">
        {signupButton ? (
          <Button
            className="btn--sign-up"
            ariaLabel={ariaLabel}
            backgroundColor={backgroundColor}
            size={size}
            type="button"
            onClick={() => navigate(url!)}
            label={label}
          />
        ) : null}
      </div>
    </div>
  );
};

export default SignupBanner;
